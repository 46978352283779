<template>
  <VueDocPreview :value="docValue" :type="docType" />
</template>
 
<script>
import VueDocPreview from "vue-doc-preview";
export default {
  components: {
    VueDocPreview,
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "report/report-template/" +
          this.$route.params.id +
          "/download",
        responseType: "blob",
      };
      var self = this;
      this.$http(options).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        console.log(url);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "20220412-070012.docx"); //or any other extension
        document.body.appendChild(link);
        self.docValue = "Test";
      });
    },
  },
  data() {
    return {
      docValue: "",
      docType: "office",
    };
  },
};
</script>